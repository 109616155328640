import React from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useAppContext } from "../components/AppContext";

const RouteItem = ({ route }) => {
  const navigation = useNavigation();
  const {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    fetchLocationById,
    audioguide,
    setAudioSpeed,
    startNewAudio,
    playAudio,
    pauseAudio,
    isAudioPlaying,
    logActivity,
  } = useAppContext();

  const handleLocationPress = async (route_) => {
    // Handle the press on a location item

    console.log(route_.locations);

    const loadedLocations = locations; // Replace this with your actual loaded locations list

    // Helper function to fetch a location by ID if it's not loaded
    const fetchLocationIfNotLoaded = async (location) => {
      const foundLocation = loadedLocations.find(
        (loc) => loc.id === location.id
      );

      if (!foundLocation) {
        console.log(
          `Location with ID ${location.id} is not loaded. Fetching...`
        );
        const fetchedLocation = await fetchLocationById(location.id); // Assume fetchLocationById fetches and returns the location
        return fetchedLocation;
      }

      return foundLocation;
    };

    const loadedLocationsPromises = route_.locations.map(
      fetchLocationIfNotLoaded
    );
    const fullyLoadedLocations = await Promise.all(loadedLocationsPromises);

    // const foundLocation = locations.find(
    //     (location) => location.id === marker.id
    //   );

    //   if (foundLocation) {
    //     console.log(foundLocation);
    //     navigation.navigate("Location", { location: foundLocation });
    //   } else {
    //     console.log("The location is not loaded. Loading...");
    //     const foundLocation = await fetchLocationById(marker.id);
    //     navigation.navigate("Location", { location: foundLocation });
    //   }

    console.log(`Pressed on ${route_.title}`);
    navigation.navigate("Route", { route_ });
  };

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => handleLocationPress(route)}
    >
      <View style={styles.thumbnailContainer}>
        <Image
          source={
            route.thumbnail
              ? { uri: route.thumbnail }
              : require("../assets/noPicture.png")
          }
          style={styles.thumbnail}
          resizeMode="cover"
        />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.locationName}>{route.title}</Text>
        <Text style={styles.description}>
          {route.description.replace(/\n/g, "").substring(0, 70) + "..."}
        </Text>
        <View style={styles.infoContainer}>
          {/* Distance */}
          <View style={styles.infoItem}>
            <Icon
              name="map-marker"
              size={16}
              color="gray"
              style={styles.icon}
            />
            <Text style={styles.infoText}>
              {Math.round(route.distance)} m away
            </Text>
          </View>
          {/* Length */}
          <View style={styles.infoItem}>
            <Icon name="map-signs" size={16} color="gray" style={styles.icon} />
            <Text style={styles.infoText}>{route.length}</Text>
          </View>
          {/* Time */}
          <View style={styles.infoItem}>
            <Icon name="clock-o" size={16} color="gray" style={styles.icon} />
            <Text style={styles.infoText}>{route.time}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  thumbnailContainer: {
    marginRight: 10,
  },
  thumbnail: {
    width: 100,
    height: 100,
    borderRadius: 5,
  },
  textContainer: {
    flex: 1,
  },
  locationName: {
    fontSize: 16,
    marginBottom: 5,
  },
  description: {
    fontSize: 12,
    color: "gray",
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
  },
  infoItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  infoText: {
    fontSize: 12,
    color: "gray",
  },
});

export default RouteItem;

import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Dimensions,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Linking } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  MenuProvider,
} from "react-native-popup-menu";
//import MapView from "react-native-maps";
import AudioControlSlider from "../components/AudioControlSlider";
import ReviewModal from "../components/ReviewModal";
import { useAppContext } from "../components/AppContext";
import ReportModal from "../components/ReportModal";
import GYGCityWidget from "../components/GYGCityWidget.web";
import GYGLocationWidget from "../components/GYGLocationWidget.web";
import { Button } from "react-native-web";
import { Routes } from "react-router-dom";
import LocationItem from "../components/LocationItem";
import { setCurrentRouteName } from "../components/NavigationBar";

const RouteScreen = ({ route }) => {
  const { route_ } = route.params;
  const navigation = useNavigation();
  const { speak, locations, followedRoute, setFollowedRoute } = useAppContext();

  const filteredLocations = route_.locations
    .map((routeLocation) =>
      locations.find((location) => location.id === routeLocation.id)
    )
    .filter(Boolean);
  //console.log(location);
  //Variable to hold the information of if the photo is enlarged.
  const [enlarged, setEnlarged] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  const toggleEnlarged = () => {
    setEnlarged(!enlarged);
  };

  const handleMapPress = () => {
    //Implement logic to open the map
    const whereTo = {
      latitude: route_.path_coordinates[0].latitude,
      longitude: route_.path_coordinates[0].longitude,
    };

    navigation.navigate("Map", { whereTo });
  };

  const handleFollowPress = () => {
    //here handle route following
    if (followedRoute == route_.id) {
      setFollowedRoute(null);
    } else {
      setFollowedRoute(route_.id);

      const whereTo = {
        latitude: route_.path_coordinates[0].latitude,
        longitude: route_.path_coordinates[0].longitude,
      };

      navigation.navigate("Map", { whereTo });
    }
  };

  //console.log("Received location:", location);
  return (
    <View style={styles.container}>
      {/* Top Red Bar with Logo */}
      <View style={styles.topBar}>
        {/* Back Button */}
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="chevron-left" size={30} color="white" />
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>

        <Image
          source={require("../assets/logonaszybkobiale.png")}
          style={styles.logo}
          resizeMode="contain"
        />

        {/* Three-dot menu */}

        <Menu>
          <MenuTrigger>
            <Icon
              style={styles.popupButton}
              name="ellipsis-v"
              size={30}
              color="red"
            />
          </MenuTrigger>
          <MenuOptions></MenuOptions>
        </Menu>
      </View>

      <ReportModal
        modalVisible={reportModalVisible}
        setModalVisible={setReportModalVisible}
        location={route_}
      />

      <View style={{ flex: 1 }}>
        {/* Your Scroll View Content */}
        <ScrollView style={styles.scrollView}>
          {/* Clickable Photo */}
          <TouchableOpacity onPress={toggleEnlarged}>
            <View
              style={
                enlarged ? styles.enlargedPhotoContainer : styles.photoContainer
              }
            >
              <Image
                style={enlarged ? styles.enlargedPhoto : styles.photo}
                source={
                  route_.thumbnail
                    ? { uri: route_.thumbnail }
                    : require("../assets/noPicture.png")
                }
              />
            </View>
          </TouchableOpacity>

          {/*Copyright information*/}
          {route_.thumbnail && (
            <Text style={styles.copyrightsText}>
              Author: {route_.thumbnail_author.replace("\n", "")} |
              <TouchableOpacity
                onPress={() => Linking.openURL(route_.license_link)}
              >
                <Text style={styles.viewSourceText}>
                  {" "}
                  {route_.thumbnail_license}{" "}
                </Text>
              </TouchableOpacity>
              | Unaltered |
              <TouchableOpacity
                onPress={() => Linking.openURL(route_.thumbnail_file_source)}
              >
                <Text style={styles.viewSourceText}> View source </Text>
              </TouchableOpacity>
            </Text>
          )}

          <View style={styles.headerContainer}>
            {/* Location Title */}
            <Text style={styles.title}>{route_.title.replace("\n", "")}</Text>
          </View>

          <View style={styles.buttonView}>
            {followedRoute != route_.id ? (
              <TouchableOpacity
                style={styles.locationButton}
                onPress={() => handleFollowPress()}
              >
                <Icon
                  name="map-signs"
                  size={24}
                  color="red"
                  style={styles.buttonIcon}
                />
                <Text style={styles.compassButtonText}>Start following</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={styles.locationButton}
                onPress={() => handleFollowPress()}
              >
                <Icon
                  name="map-signs"
                  size={24}
                  color="red"
                  style={styles.buttonIcon}
                />
                <Text style={styles.compassButtonText}>Stop following</Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity
              style={styles.locationButton}
              onPress={handleMapPress}
            >
              <Icon
                name="map"
                size={24}
                color="red"
                style={styles.buttonIcon}
              />
              <Text style={styles.compassButtonText}>See on map</Text>
            </TouchableOpacity>
          </View>

          {/* Long Description */}
          <Text style={styles.description}>{route_.description}</Text>

          {filteredLocations.map((location, index) => {
            return (
              <React.Fragment key={index}>
                <LocationItem location={location} />
              </React.Fragment>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  topBar: {
    backgroundColor: "red",
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottomBar: {
    flexDirection: "row",
    backgroundColor: "#f2f6fc",
    height: 50,
  },
  logo: {
    position: "absolute",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: 150,
    height: 40,
    zIndex: 1,
  },
  scrollView: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  photoContainer: {
    width: "100%",
    height: 300,
    overflow: "hidden",
  },
  enlargedPhotoContainer: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.75,
    resizeMode: "contain",
    overflow: "hidden",
  },
  photo: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  enlargedPhoto: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  title: {
    fontSize: 26,
    fontWeight: "bold",
    marginLeft: 3,
    alignSelf: "center",
    textAlign: "center",
    marginBottom: 14,
  },
  moreInfo: {
    fontSize: 17,
    fontWeight: "bold",
    marginTop: 20,
    marginLeft: 10,
    lineHeight: 24,
  },
  informationLinkContainer: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
  },
  informationLink: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginLeft: 10,
  },
  moreInfoText: {
    fontSize: 17,
    fontWeight: "bold",
    marginLeft: 10,
  },
  headerContainer: {
    alignItems: "center", // Center children vertically
    justifyContent: "center",
    paddingHorizontal: 3,
  },
  copyrightsText: {
    fontSize: 10,
    lineHeight: 24,
    marginLeft: 10,
    marginRight: 10,
    alignSelf: "center",
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginLeft: 10,
    marginRight: 10,
    alignSelf: "center",
  },
  backButton: {
    marginLeft: 5,
    marginRight: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  backButtonText: {
    marginLeft: 10,
    marginBottom: 4,
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
  },
  backButtonTextRed: {
    marginLeft: 10,
    marginBottom: 5,
    fontSize: 20,
    color: "red",
    fontWeight: "bold",
    userSelect: "none",
  },
  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedIcon: {
    color: "red",
  },
  unselectedIcon: {
    color: "black",
  },
  viewSourceText: {
    color: "navy",
  },
  menuButton: {
    padding: 10,
  },
  popupButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    marginRight: 10,
  },
  popupButtonText: {
    marginLeft: 10,
  },
  buttonView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 10,
  },
  locationButton: {
    flexDirection: "row",
    alignItems: "center", // Vertically center the content
  },
  compassButtonText: {
    fontSize: 18,
    color: "red",
    fontWeight: "bold",
    marginLeft: 8, // Adjusted margin between icon and text
  },
});

export default RouteScreen;

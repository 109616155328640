import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Button,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Modal,
  Pressable,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Geolocation from "react-native-geolocation-service";

import LocationItem from "../components/LocationItem";
import { useAppContext } from "../components/AppContext";
import NewUserModal from "../components/NewUserModal";
import GYGCityWidget from "../components/GYGCityWidget.web";
import NoServiceModal from "../components/NoServiceModal";
import RouteItem from "../components/RouteItem";

//const firestore = getFirestore();

//console.log("Hello Firestore");

function MainScreen({ route, navigation }) {
  const {
    user,
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    newUser,
    setNewUser,
    appOff,
    speak,
    routes,
  } = useAppContext();

  //To load the locations once the user location becomes available
  const [hasFetchedLocations, setHasFetchedLocations] = useState(false);

  //Variable for choosing between the locations and routes screens:
  const [view, setView] = useState("locations");

  useEffect(() => {
    if (userLocation && !hasFetchedLocations) {
      if (user.email != "anotherrivuss@gmail.com") {
        getNearbyLocations();
        setHasFetchedLocations(true);
      }
    }
  }, [userLocation]);

  const getCoordinates = () => {
    // Assuming getCoordinates returns a Promise
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 1000,
      };

      if (Platform.OS === "android") {
        console.log("ANDROID NOT HANDLED YET");
      } else {
        // For iOS and other platforms
        Geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
          },
          (error) => {
            console.log("Error getting location: ", error);
          },
          options
        );
      }

      // Resolve the promise with the updated user location
      resolve("Done");
    });
  };
  if (!userLocation) {
    getCoordinates();
  }

  //Get location every 5 seconds
  useEffect(() => {
    // Run getCoordinates() automatically every 5 seconds
    const intervalId = setInterval(getCoordinates, 5000);
    //console.log("Test");
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  //Refresh locations button
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const getNearbyLocations = async () => {
    await getCoordinates();
    if (!isButtonDisabled) {
      console.log(userLocation);

      //Blocking the button
      setIsButtonDisabled(true);

      fetchNearbyLocations();

      // Enable the button after 5 seconds
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);
    } else {
      console.log("The locaiton refresh is on timeout");
    }
  };

  //Getting the locations in the right order based on distance
  const sortedLocations = locations
    .slice()
    .sort((a, b) => a.distance - b.distance);

  //Same for routes
  const sortedRoutes = routes.slice().sort((a, b) => a.distance - b.distance);

  return (
    <View style={styles.container}>
      {/* Top Red Bar with Logo */}
      <View style={styles.topBar}>
        <Image
          source={require("../assets/logonaszybkobiale.png")}
          style={styles.logo}
          resizeMode="contain"
        />
        <TouchableOpacity onPress={getNearbyLocations}>
          <Icon name="repeat" size={30} color="white" style={styles.icon} />
        </TouchableOpacity>
      </View>

      {/* New Top Bar for Switching Between Locations and Routes */}
      <View style={styles.switchBar}>
        <TouchableOpacity
          style={[styles.switchOption]}
          onPress={() => setView("locations")}
        >
          <Text
            style={[
              styles.switchText,
              view === "locations" && styles.selectedText,
            ]}
          >
            Locations
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.switchOption]}
          onPress={() => setView("routes")}
        >
          <Text
            style={[
              styles.switchText,
              view === "routes" && styles.selectedText,
            ]}
          >
            Routes
          </Text>
        </TouchableOpacity>
      </View>

      {/* Separator Line */}
      <View style={styles.separatorLine} />

      {/* Scroll View for App Content */}
      <ScrollView style={styles.scrollView}>
        {/* Your App Content Goes Here */}

        {userLocation ? null : (
          <View style={styles.coordinatesText}>
            <Text>
              {
                "Looking for your location\nMake sure location access is permitted"
              }
            </Text>
          </View>
        )}
        {appOff && <NoServiceModal />}

        {/* Render content based on the selected view */}
        {view === "locations"
          ? sortedLocations.map((location, index) => {
              const shouldRenderWidget = index < 45 && (index + 1) % 15 === 5;
              return (
                <React.Fragment key={index}>
                  {shouldRenderWidget && <GYGCityWidget />}
                  <LocationItem location={location} />
                </React.Fragment>
              );
            })
          : // Routes
            sortedRoutes.map((route, index) => {
              return (
                <React.Fragment key={index}>
                  <RouteItem route={route} />
                </React.Fragment>
              );
            })}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: "white",
    justifyContent: "center",
  },
  topBar: {
    backgroundColor: "red",
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    width: 150,
    height: 40,
    alignSelf: "flex-start",
  },
  gygContainer: {
    height: 250,
  },
  scrollView: {
    flex: 1,
    backgroundColor: "white", //"#F0F0F0",
  },
  coordinatesText: {
    alignSelf: "center",
  },
  audioBar: {
    flexDirection: "row",
    backgroundColor: "red",
    height: 50,
    alignItems: "center",
  },
  playIcon: {
    color: "white",
    marginRight: 5,
    alignSelf: "flex-end",
  },
  bottomBar: {
    flexDirection: "row",
    backgroundColor: "#f2f6fc",
    height: 50,
  },

  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedIcon: {
    color: "red",
  },
  unselectedIcon: {
    color: "black",
  },
  icon: {
    marginRight: 10,
  },
  switchBar: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#fff",
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: "#ccc",
  },
  switchOption: {
    flex: 1,
    alignItems: "center",
  },
  switchText: {
    fontSize: 16,
    color: "#666",
  },
  selectedText: {
    color: "red",
    fontWeight: "bold",
    borderBottomWidth: 2,
    borderBottomColor: "red",
  },
  separatorLine: {
    height: 1,
    backgroundColor: "#ccc",
  },
});
export default MainScreen;

import React, { useRef, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Icon from "react-native-vector-icons/FontAwesome";
import { Icon as LIcon } from "leaflet";
import { TouchableOpacity } from "react-native-web";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useAppContext } from "./AppContext";
import { useNavigation } from "@react-navigation/native";

const LMap = ({ mapCentre = null }) => {
  const markerLocations = require("../assets/locsForMap20240424.json");
  const mapRef = useRef(null);

  const navigation = useNavigation();
  const {
    userLocation,
    locations,
    fetchLocationById,
    logActivity,
    routes,
    followedRoute,
    setFollowedRoute,
  } = useAppContext();

  const followedLocationIds =
    routes
      .find((route) => route.id === followedRoute)
      ?.locations.map((location) => location.id) || [];

  const centerMapOnUserLocation = () => {
    if (mapRef.current && userLocation) {
      mapRef.current.flyTo([userLocation.latitude, userLocation.longitude], 18);
    }
  };

  const stopRoutePress = () => {
    setFollowedRoute(null);
  };

  const customIcon = new LIcon({
    iconUrl: require("../assets/locicon.png"),
    iconSize: [23, 23],
  });

  const customIconSound = new LIcon({
    iconUrl: require("../assets/lociconSound.png"),
    iconSize: [35, 35],
  });

  const customUserIcon = new LIcon({
    iconUrl: require("../assets/lociconuser.png"),
    iconSize: [30, 30],
  });

  const customRouteStartIcon = new LIcon({
    iconUrl: require("../assets/lociconRoute.png"),
    iconSize: [32, 32],
  });

  // New custom icon for followed route
  const customFollowedRouteIcon = new LIcon({
    iconUrl: require("../assets/lociconBlue.png"),
    iconSize: [35, 35],
  });

  const [zoomLevel, setZoomLevel] = useState(15);
  const MapEvents = () => {
    useMapEvents({
      zoomend() {
        const zoomLeve = mapRef.current.getZoom();
        setZoomLevel(zoomLeve);
      },
    });
    return false;
  };

  const handleShowLocationButtonPress = async (marker) => {
    logActivity(marker, "Map");

    const foundLocation = locations.find(
      (location) => location.id === marker.id
    );

    if (foundLocation) {
      navigation.navigate("Location", { location: foundLocation });
    } else {
      const foundLocation = await fetchLocationById(marker.id);
      navigation.navigate("Location", { location: foundLocation });
    }
  };

  const handleRouteClick = (route) => {
    if (mapRef.current) {
      const bounds = route.path_coordinates.map((coord) => [
        coord.latitude,
        coord.longitude,
      ]);
      mapRef.current.fitBounds(bounds);
    }
  };

  const handleShowRouteButtonPress = async (route_) => {
    const loadedLocations = locations;

    const fetchLocationIfNotLoaded = async (location) => {
      const foundLocation = loadedLocations.find(
        (loc) => loc.id === location.id
      );

      if (!foundLocation) {
        const fetchedLocation = await fetchLocationById(location.id);
        return fetchedLocation;
      }

      return foundLocation;
    };

    const loadedLocationsPromises = route_.locations.map(
      fetchLocationIfNotLoaded
    );
    const fullyLoadedLocations = await Promise.all(loadedLocationsPromises);

    console.log(`Pressed on ${route_.title}`);
    navigation.navigate("Route", { route_ });
  };

  return (
    <View style={styles.mapContainer}>
      <MapContainer
        center={
          mapCentre
            ? [mapCentre.latitude, mapCentre.longitude]
            : userLocation
            ? [userLocation.latitude, userLocation.longitude]
            : [52.374004, 4.898113]
        }
        zoom={userLocation ? 17 : 15}
        style={{ width: "100%", height: "100%" }}
        ref={mapRef}
      >
        <MapEvents />
        <TileLayer
          url="https://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {userLocation && (
          <Marker
            position={[userLocation.latitude, userLocation.longitude]}
            key="userLocation"
            icon={customUserIcon}
          ></Marker>
        )}

        <MarkerClusterGroup
          chunkedLoading
          maxClusterRadius={followedRoute ? 150 : 48}
          disableClusteringAtZoom={17}
        >
          {markerLocations
            .filter((marker) => !followedLocationIds.includes(marker.id)) // Filter out markers with ids in locationIds
            .map((marker) => (
              <Marker
                position={[marker.latitude, marker.longitude]}
                key={marker.id}
                icon={marker.audioLength ? customIconSound : customIcon}
                opacity={followedRoute ? 0.2 : 0.9}
              >
                <Popup style={styles.popup}>
                  <View style={styles.popupContent}>
                    <Text>{marker.title}</Text>
                    <TouchableOpacity
                      style={styles.showLocationButton}
                      onPress={() => handleShowLocationButtonPress(marker)}
                    >
                      <Text style={{ color: "white", alignSelf: "center" }}>
                        Show details
                      </Text>
                    </TouchableOpacity>
                  </View>
                </Popup>
              </Marker>
            ))}
        </MarkerClusterGroup>

        {/* Draw the routes on the map */}
        {routes &&
          routes.map((route) => {
            // Check if this is the followed route
            const isFollowedRoute = route.id === followedRoute;

            return (
              <React.Fragment key={route.id}>
                <Polyline
                  positions={route.path_coordinates.map((coord) => [
                    coord.latitude,
                    coord.longitude,
                  ])}
                  color={isFollowedRoute ? "blue" : route.color} // Use blue for followed route
                  weight={isFollowedRoute ? 4 : 2} // Optionally, make the line thicker for the followed route
                  opacity={0.8}
                  onClick={() => handleRouteClick(route)}
                >
                  <Popup>
                    <View style={styles.popupContent}>
                      <Text>{route.title}</Text>
                      <TouchableOpacity
                        style={styles.showLocationButton}
                        onPress={() => handleShowRouteButtonPress(route)}
                      >
                        <Text style={{ color: "white", alignSelf: "center" }}>
                          Show route
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Popup>
                </Polyline>

                {/* Add a marker at the start of the route */}
                {zoomLevel >= 15 && (
                  <Marker
                    position={[
                      route.path_coordinates[0].latitude,
                      route.path_coordinates[0].longitude,
                    ]}
                    icon={customRouteStartIcon} // Use custom icon for followed route
                    onClick={() => handleRouteClick(route)}
                  >
                    <Popup>
                      <View style={styles.popupContent}>
                        <Text>{route.title}</Text>
                        <TouchableOpacity
                          style={styles.showLocationButton}
                          onPress={() => handleShowRouteButtonPress(route)}
                        >
                          <Text style={{ color: "white", alignSelf: "center" }}>
                            Show route
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </Popup>
                  </Marker>
                )}
              </React.Fragment>
            );
          })}
        {/* Markers of the followed route */}
        {markerLocations
          .filter((marker) => followedLocationIds.includes(marker.id)) // Filter out markers with ids in locationIds
          .map((marker) => (
            <Marker
              position={[marker.latitude, marker.longitude]}
              key={marker.id}
              icon={customFollowedRouteIcon}
            >
              <Popup style={styles.popup}>
                <View style={styles.popupContent}>
                  <Text>{marker.title}</Text>
                  <TouchableOpacity
                    style={styles.showLocationButton}
                    onPress={() => handleShowLocationButtonPress(marker)}
                  >
                    <Text style={{ color: "white", alignSelf: "center" }}>
                      Show details
                    </Text>
                  </TouchableOpacity>
                </View>
              </Popup>
            </Marker>
          ))}
      </MapContainer>

      <View style={styles.centerButton}>
        {followedRoute ? (
          <TouchableOpacity
            style={styles.userLocationButton}
            onPress={stopRoutePress}
          >
            <Icon name={"map-signs"} size={25} color={"blue"} />
            <Text style={styles.compassButtonText}>Stop route</Text>
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          style={styles.userLocationButton}
          onPress={centerMapOnUserLocation}
        >
          <Icon name={"location-arrow"} size={25} color={"red"} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  popupContent: {
    flexDirection: "column",
    alignItems: "center",
    minWidth: 100,
  },
  mapContainer: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  showLocationButton: {
    alignSelf: "center",
    backgroundColor: "red",
    padding: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  centerButton: {
    position: "absolute",
    bottom: 20,
    right: 10,
    backgroundColor: "white",
    borderRadius: 8,
    zIndex: 1000,
    flexDirection: "row",
  },
  userLocationButton: { padding: 10, zIndex: 1000, flexDirection: "row" },
  compassButtonText: {
    fontSize: 18,
    color: "blue",
    fontWeight: "bold",
    marginLeft: 8, // Adjusted margin between icon and text
  },
});

export default LMap;

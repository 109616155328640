import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Dimensions,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useRoute } from "@react-navigation/native";
import { Linking } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  MenuProvider,
} from "react-native-popup-menu";
//import MapView from "react-native-maps";
import AudioControlSlider from "../components/AudioControlSlider";
import ReviewModal from "../components/ReviewModal";
import { useAppContext } from "../components/AppContext";
import ReportModal from "../components/ReportModal";
import GYGCityWidget from "../components/GYGCityWidget.web";
import GYGLocationWidget from "../components/GYGLocationWidget.web";
import { Button } from "react-native-web";

const LocationScreen = ({ route, navigation }) => {
  const { location } = route.params;

  const { reviewedLocations, setReviewedLocations, speak } = useAppContext();

  //console.log(location);
  //Variable to hold the information of if the photo is enlarged.
  const [enlarged, setEnlarged] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  const toggleEnlarged = () => {
    setEnlarged(!enlarged);
  };

  const handleCompassPress = () => {
    const lat = location.latitude;
    const lon = location.longitude;
    Linking.openURL(
      "https://www.google.com/maps/search/?api=1&query=" + lat + "," + lon
    );
  };

  const handleWikipediaPress = () => {
    const wikiRef = location.title.replace(" ", "_");

    Linking.openURL("https://nl.wikipedia.org/wiki/" + wikiRef);
  };

  //console.log("Received location:", location);
  return (
    <View style={styles.container}>
      {/* Top Red Bar with Logo */}
      <View style={styles.topBar}>
        {/* Back Button */}
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="chevron-left" size={30} color="white" />
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>

        <Image
          source={require("../assets/logonaszybkobiale.png")}
          style={styles.logo}
          resizeMode="contain"
        />

        {/* Three-dot menu */}

        <Menu>
          <MenuTrigger>
            <Icon
              style={styles.popupButton}
              name="ellipsis-v"
              size={30}
              color="white"
            />
          </MenuTrigger>
          <MenuOptions>
            <MenuOption onSelect={() => setReportModalVisible(true)}>
              <View style={styles.popupButton}>
                <Icon name="flag" size={20} color="black" />
                <Text style={styles.popupButtonText}>Report</Text>
              </View>
            </MenuOption>
          </MenuOptions>
        </Menu>
      </View>

      <ReportModal
        modalVisible={reportModalVisible}
        setModalVisible={setReportModalVisible}
        location={location}
      />

      <View style={{ flex: 1 }}>
        {/* Your Scroll View Content */}
        <ScrollView style={styles.scrollView}>
          {/* Clickable Photo */}
          <TouchableOpacity onPress={toggleEnlarged}>
            <View
              style={
                enlarged ? styles.enlargedPhotoContainer : styles.photoContainer
              }
            >
              <Image
                style={enlarged ? styles.enlargedPhoto : styles.photo}
                source={
                  location.thumbnail
                    ? { uri: location.thumbnail }
                    : require("../assets/noPicture.png")
                }
              />
            </View>
          </TouchableOpacity>

          {/*Copyright information*/}
          {location.thumbnail && (
            <Text style={styles.copyrightsText}>
              Author: {location.thumbnail_author.replace("\n", "")} |
              <TouchableOpacity
                onPress={() => Linking.openURL(location.license_link)}
              >
                <Text style={styles.viewSourceText}>
                  {" "}
                  {location.thumbnail_license}{" "}
                </Text>
              </TouchableOpacity>
              | Unaltered |
              <TouchableOpacity
                onPress={() => Linking.openURL(location.thumbnail_file_source)}
              >
                <Text style={styles.viewSourceText}> View source </Text>
              </TouchableOpacity>
            </Text>
          )}

          <View style={styles.headerContainer}>
            {/* Location Title */}
            <Text style={styles.title}>{location.title.replace("\n", "")}</Text>
          </View>
          <ReviewModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            location={location}
          />
          <View style={styles.buttonView}>
            {!reviewedLocations.includes(location.id) ? (
              <TouchableOpacity
                style={styles.locationButton}
                onPress={() => setModalVisible(true)}
              >
                <Icon
                  name="star-o"
                  size={24}
                  color="red"
                  style={styles.buttonIcon}
                />
                <Text style={styles.compassButtonText}>Review</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity style={styles.locationButton}>
                <Icon
                  name="check"
                  size={24}
                  color="red"
                  style={styles.buttonIcon}
                />
                <Text style={styles.compassButtonText}>Thank you</Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity
              style={styles.locationButton}
              onPress={handleCompassPress}
            >
              <Icon
                name="compass"
                size={24}
                color="red"
                style={styles.buttonIcon}
              />
              <Text style={styles.compassButtonText}>Directions</Text>
            </TouchableOpacity>
          </View>

          <AudioControlSlider location={location}></AudioControlSlider>

          {/* Long Description */}
          <Text style={styles.description}>{location.clean_text}</Text>
          {/* More info */}
          {location.title !== "Welcome to KurioCity" && (
            <View>
              <Text style={styles.moreInfo}>Additional information:</Text>
              <View style={styles.informationLinkContainer}>
                <TouchableOpacity
                  style={styles.informationLink}
                  onPress={handleWikipediaPress}
                >
                  <Icon name="wikipedia-w" size={20} />
                  <Text style={styles.moreInfoText}>nl.wikipedia.org</Text>
                </TouchableOpacity>
              </View>
              <GYGLocationWidget />
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  topBar: {
    backgroundColor: "red",
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottomBar: {
    flexDirection: "row",
    backgroundColor: "#f2f6fc",
    height: 50,
  },
  logo: {
    position: "absolute",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: 150,
    height: 40,
    zIndex: 1,
  },
  scrollView: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  photoContainer: {
    width: "100%",
    height: 300,
    overflow: "hidden",
  },
  enlargedPhotoContainer: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.75,
    resizeMode: "contain",
    overflow: "hidden",
  },
  photo: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  enlargedPhoto: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  title: {
    fontSize: 26,
    fontWeight: "bold",
    marginLeft: 3,
    alignSelf: "center",
    textAlign: "center",
  },
  moreInfo: {
    fontSize: 17,
    fontWeight: "bold",
    marginTop: 20,
    marginLeft: 10,
    lineHeight: 24,
  },
  informationLinkContainer: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
  },
  informationLink: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginLeft: 10,
  },
  moreInfoText: {
    fontSize: 17,
    fontWeight: "bold",
    marginLeft: 10,
  },
  headerContainer: {
    alignItems: "center", // Center children vertically
    justifyContent: "center",
    paddingHorizontal: 3,
  },
  copyrightsText: {
    fontSize: 10,
    lineHeight: 24,
    marginLeft: 10,
    marginRight: 10,
    alignSelf: "center",
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginLeft: 10,
    marginRight: 10,
    alignSelf: "center",
  },
  backButton: {
    marginLeft: 5,
    marginRight: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  backButtonText: {
    marginLeft: 10,
    marginBottom: 4,
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
  },
  backButtonTextRed: {
    marginLeft: 10,
    marginBottom: 5,
    fontSize: 20,
    color: "red",
    fontWeight: "bold",
    userSelect: "none",
  },
  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedIcon: {
    color: "red",
  },
  unselectedIcon: {
    color: "black",
  },
  viewSourceText: {
    color: "navy",
  },
  menuButton: {
    padding: 10,
  },
  popupButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    marginRight: 10,
  },
  popupButtonText: {
    marginLeft: 10,
  },
  buttonView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginVertical: 16, // Added some margin for better spacing
  },
  locationButton: {
    flexDirection: "row",
    alignItems: "center", // Vertically center the content
  },
  compassButtonText: {
    fontSize: 18,
    color: "red",
    fontWeight: "bold",
    marginLeft: 8, // Adjusted margin between icon and text
  },
});

export default LocationScreen;

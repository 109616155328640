// AppContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

import { firestore, storage, auth } from "../../App";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore/lite";
import { getDownloadURL, ref } from "firebase/storage";
import * as geofire from "geofire-common";
import Sound from "react-native-web-sound";
import { onAuthStateChanged, signOut } from "firebase/auth";
import * as Speech from "expo-speech";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [userLocation, setUserLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [user, setUser] = useState(null);
  const [reCaptchaVerified, setreCaptchaVerified] = useState(false);
  const [newUser, setNewUser] = useState(false); //For the welcome scren to appear
  const [newGoogle, setNewGoogle] = useState(false); //For the T&C when a new user gets logged in with Google
  const [appOff, setAppOff] = useState(false); //To check if the killswitch is on (for critical errors)
  const [appOffMessage, setAppOffMessage] = useState("Please try again later");
  const [routes, setRoutes] = useState(
    require("../assets/exampleRouteStructure.json")
  ); //The routes are loaded from a file, but we still need setRoutes to update the distances
  const [followedRoute, setFollowedRoute] = useState(null);
  const appVersion = "v1.2.2";

  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          console.log("Logged in");
          setUser(user);

          //Check if the killswitch is activated
          try {
            const appOnDoc = await getDoc(
              doc(firestore, "Killswitch", "Switch")
            );
            if (appOnDoc.exists()) {
              const data = appOnDoc.data();
              //console.log(data.message);
              setAppOff(data.appOff);
              setAppOffMessage(data.message);
            } else {
              console.log("Document does not exist");
            }
          } catch (error) {
            // Handle error if necessary
            console.error("Error fetching document:", error);
          }

          //Check if the user has accepted the T&C (this is not a given with Google login)
          try {
            const termsAccept = await getDoc(
              doc(firestore, "UserInfo/" + user.email)
            );
            console.log(termsAccept);
            if (termsAccept.exists()) {
              console.log("T&C accepted");
            } else {
              setNewGoogle(true);
              setNewUser(true);
              console.log("User has not accepted T&C yet");
            }
          } catch {
            console.log("Error when checking if T&C have been accepted");
            setUser(null);
            signOut(auth);
          }

          // Other logic if needed
        } else {
          console.log("Logged out");
          setUser(null);
        }
      });

      // Return the unsubscribe function for cleanup
      return unsubscribe;
    };

    // Call the function to fetch data
    fetchData();
  }, []); // Empty dependency array to only run the effect on mount/unmount

  //Storing the last locations so you don't load the last 10 geohashes again
  const [recentlyLoadedGeohashes, setRecentlyLoadedGeohashes] = useState([]);

  useEffect(() => {
    // Initialize locations or fetch them from local storage, if needed
    // setLocations([...]);
    if (userLocation && locations.length > 0) {
      updateLocationsAndRoutesWithDistances();
      //console.log(locations);
    }
  }, [userLocation]);

  //I want to make sure you can't spam reviews, so all reviews in the session are saved
  //In the future download the user's reviews.
  const [reviewedLocations, setReviewedLocations] = useState(["999999999"]);

  ////////////////////////////////////////////////////////////////////////////////////////////// HANDLING SOUND
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isAudioFinished, setIsAudioFinished] = useState(true);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  //TTS
  const speak = (text) => {
    // console.log(text);
    Speech.speak(text, {
      language: "en",
      rate: playbackSpeed,
      onDone: () => {
        setIsAudioPlaying(false);
        setIsAudioFinished(true);
      },
    });
  };

  ///Proper audio
  const [loadedOnlineAudioguide, setLoadedOnlineAudioguide] = useState(
    new Sound(require("../assets/welcome.mp3"), (error) => {
      if (error) {
        console.log("failed to load the sound", error);
        return;
      }
      //audioguide.play();
      // when loaded successfully
      loadedOnlineAudioguide.log(
        "duration in seconds: " +
          loadedOnlineAudioguide.getDuration() +
          "number of channels: " +
          loadedOnlineAudioguide.getNumberOfChannels()
      );
    })
  );

  const [audioLocation, setAudioLocation] = useState({
    audio: null,
    clean_text:
      "Welcome to Amsterdam! \nThank you for experiencing it with KurioCity. \nSelect a nearby location to learn about or browse the map to discover the exhibits of the city museum.",
    license_link: null,
    thumbnail: null,
    thumbnail_author: null,
    thumbnail_file_source: null,
    thumbnail_license: null,
    thumbnail_file_name: null,
    title: "Welcome to KurioCity",
    id: "999999999",
  });
  const [currentAudioPosition, setCurrentAudioPosition] = useState(0);

  Sound.setCategory("Playback");

  loadedOnlineAudioguide.setVolume(1);

  const setAudioSpeed = (speed) => {
    setPlaybackSpeed(speed);
    loadedOnlineAudioguide.setSpeed(speed);
  };

  const startNewAudio = (location) => {
    loadedOnlineAudioguide.pause(); // Pause the currently playing audio
    Speech.stop(); //Handling two cases, due to some locations using expo speech

    //Update the info given to the player (thumbnail, id, etc.)
    setAudioLocation(location);
    setCurrentAudioPosition(0);
    const newAudioguide = new Sound(location.audio, (error) => {
      if (error) {
        console.log("failed to load the sound", error);
        return;
      }
    });
    setLoadedOnlineAudioguide(newAudioguide);
    //Set again in case the track changes
    newAudioguide.setSpeed(playbackSpeed);
    setIsAudioPlaying(true);
    setIsAudioFinished(false);

    //Play, but if it is not downloaded yet, wait and try again
    const playAfterDownload = () => {
      if (location.audioLength) {
        newAudioguide.play((success) => {
          if (success) {
            setIsAudioPlaying(false);
            setIsAudioFinished(true);
          } else {
            // ADD timeout here
            setTimeout(playAfterDownload, 200);
          }
        });
      } else {
        console.log("No audio");
        speak(location.clean_text);
      }
    };
    playAfterDownload();
  };

  const playAudio = () => {
    if (
      audioLocation.audioLength ||
      audioLocation.title == "Welcome to KurioCity"
    ) {
      if (loadedOnlineAudioguide) {
        loadedOnlineAudioguide.pause(); // Pause the currently playing audio
      }
      setIsAudioPlaying(true);
      loadedOnlineAudioguide.play((success) => {
        if (success) {
          setIsAudioPlaying(false);
          setIsAudioFinished(true);
        }
      });
    } else {
      setIsAudioPlaying(true);
      Speech.resume();
    }
  };

  const pauseAudio = () => {
    setIsAudioPlaying(false);
    loadedOnlineAudioguide.pause();
    Speech.pause();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAudioPlaying) {
        setCurrentAudioPosition(loadedOnlineAudioguide.getCurrentTime());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isAudioPlaying, loadedOnlineAudioguide]);

  const getTotalAudioLength = () => {
    return loadedOnlineAudioguide.getDuration();
  };

  const setCurrentAudioPositionSlider = (time) => {
    time = Math.round(time);
    loadedOnlineAudioguide.setCurrentTime(time);
  };

  /////////////////////////////////////////////////////////////////END OF HANDLING SOUND

  //FUNCTION FOR GETTING NEARBY LOCATIONS AND SAVING THEM IN THE locations
  const fetchNearbyLocations = async () => {
    console.log(userLocation);
    if (!userLocation) {
      console.error("User location not available.");
      return;
    }

    try {
      const center = [userLocation.latitude, userLocation.longitude];
      const radius = 85;
      const bounds = geofire.geohashQueryBounds(center, radius);

      // Create an array of promises for each geohash query
      const notInArray =
        recentlyLoadedGeohashes.length > 0
          ? recentlyLoadedGeohashes.slice(-10) // Take the last 10 geohashes
          : ["invalidGeohash"];

      // Create an array of promises for each geohash query  // POTENTIALLY INCLUDE THE LOGIC OF CHECKING WHICH GEOHASHES TO ASK FOR IN THE MAP THING
      const promises = bounds.map((b) => {
        return getDocs(
          query(
            collection(firestore, "Locations2"),
            where("geohash8", ">=", b[0]),
            where("geohash8", "<=", b[1]),
            where("geohash8", "not-in", notInArray),
            limit(15)
          )
        );
      });

      // Collect all the query results together into a single list
      const snapshots = await Promise.all(promises);

      // Extract the new locations
      const newLocationsSet = new Set();
      snapshots.forEach((snapshot) => {
        snapshot.forEach((doc) => {
          const locationData = doc.data();
          console.log("New location:" + locationData.title);

          // Convert locationData to a JSON string for comparison
          const locationString = JSON.stringify(locationData);

          // Add the JSON string to the Set
          newLocationsSet.add(locationString);
        });
      });

      //THIS IS GOING TO BE SUPER UGLY BUT WHATEVER I DON"T CARE
      //IF THERE IS NOT ENOUGH LOCATIONS, INCREASE THE RADIUS OF SEARCH
      if (newLocationsSet.size < 4) {
        console.log("NOT ENOUGH LOCATIONS");
        const radius = 188;
        const bounds = geofire.geohashQueryBounds(center, radius);

        // Create an array of promises for each geohash query
        const notInArray =
          recentlyLoadedGeohashes.length > 0
            ? recentlyLoadedGeohashes.slice(-10) // Take the last 10 geohashes
            : ["invalidGeohash"];

        // Create an array of promises for each geohash query  // POTENTIALLY INCLUDE THE LOGIC OF CHECKING WHICH GEOHASHES TO ASK FOR IN THE MAP THING
        const promises = bounds.map((b) => {
          return getDocs(
            query(
              collection(firestore, "Locations2"),
              where("geohash8", ">=", b[0]),
              where("geohash8", "<=", b[1]),
              where("geohash8", "not-in", notInArray),
              limit(15)
            )
          );
        });

        // Collect all the query results together into a single list
        const snapshots = await Promise.all(promises);

        // Extract the new locations
        snapshots.forEach((snapshot) => {
          snapshot.forEach((doc) => {
            const locationData = doc.data();
            console.log("New location:" + locationData.title);

            // Convert locationData to a JSON string for comparison
            const locationString = JSON.stringify(locationData);

            // Add the JSON string to the Set
            newLocationsSet.add(locationString);
          });
        });
        console.log(newLocationsSet.size);
      }

      console.log(newLocationsSet.size);
      // Convert the set back to an array
      const newLocations = Array.from(newLocationsSet).map(JSON.parse);

      // Update the recently loaded geohashes - create a set so there are no duplicate geohases and take just the 10 last elements
      setRecentlyLoadedGeohashes((prevGeohashes) => {
        const uniqueGeohashes = [
          ...new Set([
            ...prevGeohashes,
            ...newLocations.map((loc) => loc.geohash),
          ]),
        ];
        return uniqueGeohashes.slice(-10);
      });

      // Filter out locations that are already in the local state
      const uniqueNewLocations = newLocations.filter((newLoc) => {
        const isDuplicate = locations.some((loc) => loc.id === newLoc.id);
        if (isDuplicate) {
          console.log(`Duplicate location found with ID: ${newLoc.id}`);
        }
        return !isDuplicate;
      });

      //GETTING the thumbnail urls for the images
      const storageRef = ref(storage, "Thumbnails");

      //List of licenses I am accepting
      const validLicenses = [
        "Public domain",
        "CC BY-SA 4.0",
        "CC BY-SA 3.0",
        "CC0",
        "CC BY-SA 3.0 nl",
        "CC BY 3.0",
        "CC BY 2.0",
        "CC BY 4.0",
        "CC BY-SA 2.5",
        "CC BY-SA 2.0",
        "CC BY 2.5 nl",
        "CC BY 2.5",
        "CC BY-SA 2.5 nl",
      ];

      const fetchThumbnailUrls = async () => {
        return Promise.all(
          uniqueNewLocations.map(async (location) => {
            if (validLicenses.includes(location.thumbnail_license)) {
              const thumbnailFileName = `${
                location.id
              }.${location.thumbnail_file_name.split(".").pop()}`;
              const thumbnailRef = ref(storageRef, thumbnailFileName);

              try {
                const downloadURL = await getDownloadURL(thumbnailRef);
                location.thumbnail = downloadURL;

                //Add the link to the license to make it easy to direct to it
                if (location.thumbnail_license == "Public domain") {
                  location.license_link =
                    "https://en.wikipedia.org/wiki/Public_domain";
                } else if (location.thumbnail_license == "CC BY-SA 4.0") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by-sa/4.0/deed.en";
                } else if (location.thumbnail_license == "CC BY-SA 3.0") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by-sa/3.0/deed.en";
                } else if (location.thumbnail_license == "CC0") {
                  location.license_link =
                    "https://creativecommons.org/publicdomain/zero/1.0/deed.en";
                } else if (location.thumbnail_license == "CC BY-SA 3.0 nl") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by-sa/3.0/nl/deed.en";
                } else if (location.thumbnail_license == "CC BY 3.0") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by/3.0/deed.en";
                } else if (location.thumbnail_license == "CC BY 2.0") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by/2.0/deed.en";
                } else if (location.thumbnail_license == "CC BY 4.0") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by/4.0/deed.en";
                } else if (location.thumbnail_license == "CC BY-SA 2.5") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by-sa/2.5/deed.en";
                } else if (location.thumbnail_license == "CC BY-SA 2.0") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by-sa/2.0/deed.en";
                } else if (location.thumbnail_license == "CC BY 2.5 nl") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by/2.5/nl/deed.en";
                } else if (location.thumbnail_license == "CC BY 2.5") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by/2.5/deed.en";
                } else if (location.thumbnail_license == "CC BY-SA 2.5 nl") {
                  location.license_link =
                    "https://creativecommons.org/licenses/by/2.5/deed.en";
                } else {
                  location.license_link = null;
                }
              } catch (error) {
                console.error("Error fetching download URL:", error);
                location.thumbnail = null;
              }
            } else {
              location.thumbnail = null;
              location.license_link = null;
            }
          })
        );
      };

      // Fetch download URLs and wait for completion                     TO BE IMPLEMENTED - NOW DOING USING A SAMPLE SOUND ASSET
      await fetchThumbnailUrls();

      const audioStorageRef = ref(storage, "Audioguides");
      const fetchAudioUrls = async () => {
        return Promise.all(
          uniqueNewLocations.map(async (location) => {
            const audioFileName = location.id + ".mp3";
            const audioRef = ref(audioStorageRef, audioFileName);

            try {
              if (location.audioLength) {
                const downloadURL = await getDownloadURL(audioRef);
                location.audio = downloadURL;
              } else {
                location.audio = null;
              }
            } catch (error) {
              //Not sure if all the errors are an issue, but for now whatever. If the audio is not there, simply get the error.
              //console.error("Error fetching download URL:", error);
              location.audio = null;
            }
          })
        );
      };
      await fetchAudioUrls();

      // Update the locations directly using the callback form
      setLocations((prevLocations) => {
        const updatedLocations = [...prevLocations, ...uniqueNewLocations];

        // Recalculate distances immediately after updating locations
        const locationsWithDistances = updatedLocations.map((location) => ({
          ...location,
          distance: calculateDistance(
            {
              latitude: userLocation.latitude,
              longitude: userLocation.longitude,
            },
            {
              latitude: location.latitude,
              longitude: location.longitude,
            }
          ),
        }));

        return locationsWithDistances;
      });

      // Recalculate distances after updating locations
      //updateLocationsWithDistances();

      //Testing
      console.log(locations);
    } catch (error) {
      console.error("Error fetching nearby locations:", error);
    }
  };

  const fetchLocationById = async (locationId) => {
    try {
      // get a promise for the location

      const locationRef = doc(firestore, "Locations2/" + locationId);

      const locationDoc = await getDoc(locationRef);

      const locationData = locationDoc.data();

      console.log("Location by ID:", locationData.title);

      if (locations.some((loc) => loc.id === locationId.id)) {
        console.log("WARNING: Duplicate location loaded by id. Aborting");
        return;
      }

      //GETTING the thumbnail urls for the images
      const storageRef = ref(storage, "Thumbnails");

      //List of licenses I am accepting
      const validLicenses = [
        "Public domain",
        "CC BY-SA 4.0",
        "CC BY-SA 3.0",
        "CC0",
        "CC BY-SA 3.0 nl",
        "CC BY 3.0",
        "CC BY 2.0",
        "CC BY 4.0",
        "CC BY-SA 2.5",
        "CC BY-SA 2.0",
        "CC BY 2.5 nl",
        "CC BY 2.5",
        "CC BY-SA 2.5 nl",
      ];

      if (validLicenses.includes(locationData.thumbnail_license)) {
        const thumbnailFileName = `${locationId}.${locationData.thumbnail_file_name
          .split(".")
          .pop()}`;
        const thumbnailRef = ref(storageRef, thumbnailFileName);

        try {
          const downloadURL = await getDownloadURL(thumbnailRef);
          locationData.thumbnail = downloadURL;

          if (locationData.thumbnail_license == "Public domain") {
            locationData.license_link =
              "https://en.wikipedia.org/wiki/Public_domain";
          } else if (locationData.thumbnail_license == "CC BY-SA 4.0") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by-sa/4.0/deed.en";
          } else if (locationData.thumbnail_license == "CC BY-SA 3.0") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by-sa/3.0/deed.en";
          } else if (locationData.thumbnail_license == "CC0") {
            locationData.license_link =
              "https://creativecommons.org/publicdomain/zero/1.0/deed.en";
          } else if (locationData.thumbnail_license == "CC BY-SA 3.0 nl") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by-sa/3.0/nl/deed.en";
          } else if (locationData.thumbnail_license == "CC BY 3.0") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by/3.0/deed.en";
          } else if (locationData.thumbnail_license == "CC BY 2.0") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by/2.0/deed.en";
          } else if (locationData.thumbnail_license == "CC BY 4.0") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by/4.0/deed.en";
          } else if (locationData.thumbnail_license == "CC BY-SA 2.5") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by-sa/2.5/deed.en";
          } else if (locationData.thumbnail_license == "CC BY-SA 2.0") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by-sa/2.0/deed.en";
          } else if (locationData.thumbnail_license == "CC BY 2.5 nl") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by/2.5/nl/deed.en";
          } else if (locationData.thumbnail_license == "CC BY 2.5") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by/2.5/deed.en";
          } else if (locationData.thumbnail_license == "CC BY-SA 2.5 nl") {
            locationData.license_link =
              "https://creativecommons.org/licenses/by/2.5/deed.en";
          } else {
            locationData.license_link = null;
          }
        } catch (error) {
          console.error("Error fetching download URL:", error);
          locationData.thumbnail = null;
          locationData.license_link = null;
        }
      } else {
        locationData.thumbnail = null;
        locationData.license_link = null;
      }

      //Fetching the audio
      const audioStorageRef = ref(storage, "Audioguides");

      const audioFileName = locationData.id + ".mp3";
      const audioRef = ref(audioStorageRef, audioFileName);

      try {
        if (locationData.audioLength) {
          const downloadURL = await getDownloadURL(audioRef);
          locationData.audio = downloadURL;
        } else {
          locationData.audio = null;
        }
      } catch (error) {
        //Not sure if all the errors are an issue, but for now whatever. If the audio is not there, simply get the error.
        //console.error("Error fetching download URL:", error);
        locationData.audio = null;
      }

      // Calculate distance if user location is available
      if (userLocation) {
        locationData.distance = calculateDistance(
          {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
          {
            latitude: locationData.latitude,
            longitude: locationData.longitude,
          }
        );
      } else {
        locationData.distance = 0;
      }

      // Add the downloaded location to the locations array
      setLocations((prevLocations) => {
        const updatedLocations = [...prevLocations, locationData];

        // Recalculate distances immediately after updating locations
        const locationsWithDistances = updatedLocations.map((location) => {
          let distance = 0; // Default distance is 0
          if (userLocation) {
            // If user location is available, calculate distance
            distance = calculateDistance(
              {
                latitude: userLocation.latitude,
                longitude: userLocation.longitude,
              },
              {
                latitude: location.latitude,
                longitude: location.longitude,
              }
            );
          }
          return {
            ...location,
            distance: distance,
          };
        });

        return locationsWithDistances;
      });

      return locationData;
    } catch (error) {
      console.error("Error fetching nearby locations:", error);
    }
  };

  //FUNCTION TO CALCULATE DISTANCE BETWEEN TWO LOCATIONS
  const calculateDistance = (location1, location2) => {
    try {
      const R = 6371; // Earth radius in kilometers
      const dLat = deg2rad(location2.latitude - location1.latitude);
      const dLon = deg2rad(location2.longitude - location1.longitude);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(location1.latitude)) *
          Math.cos(deg2rad(location2.latitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c * 1000; // Distance in meters
      return distance;
    } catch {
      console.log("Can't calculate distance");
      return 0;
    }
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  //FUNCTION TO UPDATE THE DISTANCES
  const updateLocationsAndRoutesWithDistances = () => {
    if (userLocation) {
      //First update the location distances
      const locationsWithDistances = locations.map((location) => {
        const distance = calculateDistance(
          {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
          {
            latitude: location.latitude,
            longitude: location.longitude,
          }
        );
        return { ...location, distance };
      });

      setLocations(locationsWithDistances);

      //Update the distances to the first points of routes (for now). In the future consider making it the closest point in general
      const routesWithDistances = routes.map((route) => {
        const distance = calculateDistance(
          {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
          {
            latitude: route.path_coordinates[0].latitude,
            longitude: route.path_coordinates[0].longitude,
          }
        );
        return { ...route, distance };
      });

      setRoutes(routesWithDistances);
    }
  };

  //FUNCTION TO LOG THE USER'S ACTIVITY - save what location is placed and where
  const logActivity = (location, source) => {
    const locActivityCollection = collection(firestore, "Activity");

    const excludedEmails = [
      "anotherrivuss@gmail.com",
      "anotherrivuss2@gmail.com",
    ];

    if (!excludedEmails.includes(user.email)) {
      addDoc(locActivityCollection, {
        user: user.email,
        locationID: location.id,
        locationTitle: location.title,
        source: source,
        date: serverTimestamp(),
      });
    }
  };

  const contextValue = {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    fetchLocationById,
    audioguide: loadedOnlineAudioguide,
    setAudioSpeed,
    startNewAudio,
    playAudio,
    pauseAudio,
    isAudioPlaying,
    audioLocation,
    currentAudioPosition,
    getTotalAudioLength,
    setCurrentAudioPositionSlider,
    reviewedLocations,
    setReviewedLocations,
    user,
    setUser,
    logActivity,
    reCaptchaVerified,
    setreCaptchaVerified,
    newUser,
    setNewUser,
    appOff,
    appOffMessage,
    newGoogle,
    setNewGoogle,
    speak,
    isAudioFinished,
    setIsAudioFinished,
    routes,
    followedRoute,
    setFollowedRoute,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

import React from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useAppContext } from "../components/AppContext";

const LocationItem = ({ location }) => {
  const navigation = useNavigation();
  const {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    fetchLocationById,
    audioguide,
    setAudioSpeed,
    startNewAudio,
    playAudio,
    pauseAudio,
    isAudioPlaying,
    logActivity,
  } = useAppContext();

  const handleLocationPress = (location) => {
    // Handle the press on a location item

    console.log(`Pressed on ${location.title}`);
    logActivity(location, "Nearby");

    navigation.navigate("Location", { location });
  };

  const handlePlayButtonClick = (location) => {
    // Handle the play button click
    startNewAudio(location);
    console.log(`Clicked play button for ${location.title}`);
  };

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => handleLocationPress(location)}
    >
      <View style={styles.thumbnailContainer}>
        <Image
          source={
            location.thumbnail
              ? { uri: location.thumbnail }
              : require("../assets/noPicture.png")
          }
          //source={require("../assets/LocationThumbnails/testImage.jpg")} //
          style={styles.thumbnail}
          resizeMode="cover"
        />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.locationName}>{location.title}</Text>
        <Text style={styles.distance}>
          {location.clean_text.replace(/\n/g, "").substring(0, 70) + "..."}
        </Text>

        <View style={styles.infoContainer}>
          {/* Distance */}
          <View style={styles.infoItem}>
            <Icon
              name="map-marker"
              size={16}
              color="gray"
              style={styles.icon}
            />
            <Text style={styles.infoText}>
              {Math.round(location.distance)} meters away
            </Text>
          </View>
        </View>
      </View>

      <TouchableOpacity
        style={styles.playButton}
        onPress={() => handlePlayButtonClick(location)}
      >
        {/* Your play button icon or text goes here */}
        <Icon name="play" size={24} style={styles.playButton} />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  thumbnailContainer: {
    marginRight: 10,
  },
  thumbnail: {
    width: 100,
    height: 100,
    borderRadius: 5,
  },
  textContainer: {
    flex: 1,
  },
  locationName: {
    fontSize: 16,
    marginBottom: 5,
  },
  distance: {
    fontSize: 12,
    color: "gray",
  },
  playButton: {
    marginLeft: 10,
    color: "gray",
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
  },
  infoItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  infoText: {
    fontSize: 12,
    color: "gray",
  },
});

export default LocationItem;

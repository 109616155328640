import React, { Fragment, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
//WEB IMPORTS - CONSIDER ADDING RIGHT ANDROID/IOS IMPORTS LATER
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getRedirectResult,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
//Not sure if I should be getting Firestore here, but for now let's have it
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
  batch,
  writeBatch,
  connectFirestoreEmulator,
} from "firebase/firestore/lite";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SafeAreaView, View } from "react-native-web";

import TestScreen from "./app/screens/TestScreen";
import MainScreen from "./app/screens/MainScreen";
import LoginScreen from "./app/screens/LogInScreen";
import LoginScreen2 from "./app/screens/LogInScreen2";
import ForgotPasswordScreen from "./app/screens/ForgotPasswordScreen";
import ProfileScreen from "./app/screens/ProfileScreen";
import LocationScreen from "./app/screens/LocationScreen";
import { AppProvider } from "./app/components/AppContext";
import { getStorage } from "firebase/storage";
import MapScreen from "./app/screens/MapScreen";
import NavigationBar from "./app/components/NavigationBar";
import AudioControlsBar from "./app/components/AudioControlsBar";
import { MenuProvider } from "react-native-popup-menu";
import MakeAccountScreen from "./app/screens/MakeAccountScreen";
import RouteScreen from "./app/screens/RouteScreen";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDmaXbLYeyGzrrMkSoiUx63qZDNn4QYYDU",
  authDomain: "app.kuriocity.com",
  projectId: "local-discovery-project",
  storageBucket: "local-discovery-project.appspot.com",
  messagingSenderId: "447956890636",
  appId: "1:447956890636:web:51a8e6be531a5bc8cf2a29",
  measurementId: "G-HVKTH2VVVJ",
});

const analytics = getAnalytics(firebaseApp);

//Firestore configuration
export const firestore = getFirestore();
export const storage = getStorage();

//connectFirestoreEmulator(firestore, "127.0.0.1", 8080); //EMULATOR

export const auth = getAuth();
// connectAuthEmulator(auth, "http://localhost:9099"); //Theoretically  for the emulator but the emulators are hard to turn on with react Expo so I just do stuff on production (I know I know)
setPersistence(auth, browserSessionPersistence);

export const loginEmailPassword = async (email, password) => {
  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    console.log(userCredentials.user);
    return userCredentials.user;
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Rethrow the error to handle it in the LoginScreen.js file
  }
};

export const handleLogout = async () => {
  await signOut(auth);
};

//////////////////////////////FUCK ZONE - I CAN'T BELIEVE I ENDED UP HERE - DO NOT INCLUDE IN THE FINAL BUILD - TEMPORARY TOOL - REMOVE FILES LATER
//Remember to enable writes to the database in rules before using
//Define what you want to add
/////const data = require("./app/assets/ams_gen_clean_20240424_new.json");
async function addJsonDocuments() {
  const batchSize = 250; // Maximum number of operations in one batch
  let batch = writeBatch(firestore);

  // Function to execute batched write and reset the batch
  const executeBatch = async () => {
    await batch.commit();
    batch = writeBatch(firestore); // Reset the batch
  };

  // Batch and rate limit the data upload
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    const locCollection = doc(firestore, "Locations2/" + obj.id);

    // Set data in the batch
    setDoc(locCollection, obj, { merge: true }, batch);

    // If batch size reached, execute the batch and reset
    if ((i + 1) % batchSize === 0) {
      console.log("Sending batch");
      await executeBatch();
    }
    console.log("Doc added to batch");
    // Introduce a delay between operations to rate limit
    await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the delay as needed
  }

  // Execute any remaining operations in the last batch
  console.log("Sending last batch");
  await executeBatch();
}

/////////////////////////////////////////////////////////////////////////

const Stack = createNativeStackNavigator();

export default function App() {
  /////addJsonDocuments();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Logged in");
        setUser(user);
      } else {
        console.log("Logged out");
        setUser(null);
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <AppProvider>
      <SafeAreaView style={styles.containerSafe}>
        <MenuProvider>
          <NavigationContainer>
            {user ? (
              <View style={styles.navigationContainer}>
                <Stack.Navigator initialRouteName="Main">
                  <Stack.Screen
                    name="Home"
                    component={MainScreen}
                    options={{ headerShown: false }}
                  />

                  <Stack.Screen
                    name="Map"
                    component={MapScreen}
                    options={{ headerShown: false }}
                  />

                  <Stack.Screen
                    name="Profile"
                    component={ProfileScreen}
                    options={{ headerShown: false }}
                  />

                  <Stack.Screen
                    name="Location"
                    component={LocationScreen}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    name="Route"
                    component={RouteScreen}
                    options={{ headerShown: false }}
                  />
                </Stack.Navigator>
                <AudioControlsBar />

                <NavigationBar styles={styles.navigationContainer} />
              </View>
            ) : (
              <Stack.Navigator initialRouteName="Login">
                <Stack.Screen
                  name="Login"
                  component={LoginScreen2}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name="Forgor"
                  component={ForgotPasswordScreen}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name="NewAccount"
                  component={MakeAccountScreen}
                  options={{ headerShown: false }}
                />
              </Stack.Navigator>
            )}
          </NavigationContainer>
        </MenuProvider>
      </SafeAreaView>
    </AppProvider>
  );
}

// return <TestScreen />;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  containerSafe: {
    flex: 1,
    backgroundColor: "red",
    position: "relative",
  },
  navigationContainer: {
    flex: 1,
  },
  br: {
    height: 12,
  },
  btn: {
    backgroundColor: "#222",
    padding: 10,
  },
  btnText: {
    color: "#fff",
  },
});
